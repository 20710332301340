import type { ValidationError } from '@IJGN/potential'
import type { ZodError } from 'zod'
import { backendValidationErrorMessage } from '../lib/message'

const handleValidationErrors = (errors: ZodError) => {
  const messages = Object.fromEntries(
    errors.errors.map(error => {
      const { path, message } = error
      return [path[0], message]
    }),
  )
  return messages
}

// TODO: 何もしてない？？
const handleBackendValidationErrors = (validationError: ValidationError): string => {
  return backendValidationErrorMessage
}

export { handleValidationErrors, handleBackendValidationErrors }
